@media (max-width: 1120px) {}

@media (max-width: 992px) {

  .hero_area {
    height: auto;
  }

  .slider_section {

    padding-top: 75px;
    padding-bottom: 150px;

  }

  .about_section .img-box {
    margin: 0 5%;
  }

  .about_section .detail-box {
    margin-right: 5%;
  }

  .service_section .service_container .box {
    -ms-flex-preferred-size: 48%;
    flex-basis: 48%;
  }


}

@media (max-width: 768px) {


  .slider_section .detail_box {
    text-align: center;
  }

  .slider_section .img-box {

    margin: 75px 0;

  }

  .about_section .img-box {
    margin: 0;
    margin-bottom: 55px;
  }

  .about_section .detail-box {
    margin-right: 0;
  }

  .contact_section .contact-form {
    padding: 25px 0;
  }

  .info_section .info_contact {

    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .info_section .info_form .social_box {
    justify-content: center;
  }

  .info_section .info_form .social_box a {
    margin: 0 10px;
  }
}

@media (max-width: 576px) {
  .service_section .service_container .box {
    -ms-flex-preferred-size: 98%;
    flex-basis: 98%;
  }
}

@media (max-width: 480px) {}

@media (max-width: 420px) {}

@media (max-width: 360px) {}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}